<template>
  <div class="container-fluid">
    <card class="no-border-card">
      <div class="row cards-grid">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CONTACTS)"
          :to="{ name: 'List Contacts' }"
          class="col-6 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-circle-08"></i>
              </span>
              <small>{{ $t("COMMON.CONTACTS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)"
          :to="{ name: 'List Customers' }"
          class="col-6 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-circle-08"></i>
              </span>
              <small>{{ $t("COMMON.CUSTOMERS") }}</small>
            </a>
          </div>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
